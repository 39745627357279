import { ElementType } from 'react';
import cx from 'classnames';

type PillProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    color?: 'primary' | 'secondary' | 'gray-200' | 'gray-800' | 'green' | 'red';
    padding?: 'sm' | 'md' | 'lg';
    className?: string;
  }
>;

const Pill = <C extends ElementType = 'span'>({
  as,
  padding = 'sm',
  className = '',
  color = 'secondary',
  children = null,
  ...props
}: PillProps<C>) => {
  const Component = as || 'span';
  return (
    <Component
      className={cx(
        'align-middle rounded-full text-xs font-medium inline-block',
        {
          'text-white bg-theme-primary': color === 'primary',
          'text-white bg-theme-secondary': color === 'secondary',
          'text-gray-800 bg-gray-200': color === 'gray-200',
          'text-gray-200 bg-gray-800': color === 'gray-800',
          'text-green-600 bg-green-100': color === 'green',
          'text-red-600 bg-red-100': color === 'red',
          'px-2 py-px': padding === 'sm',
          'px-3 py-1': padding === 'md',
          'px-3 py-2': padding === 'lg',
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Pill;
